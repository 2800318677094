// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-colophon-jsx": () => import("./../src/pages/colophon.jsx" /* webpackChunkName: "component---src-pages-colophon-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-blog-a-better-album-ranking-algorithm-index-mdx": () => import("./../src/pages/blog/a-better-album-ranking-algorithm/index.mdx" /* webpackChunkName: "component---src-pages-blog-a-better-album-ranking-algorithm-index-mdx" */),
  "component---src-pages-blog-fetch-with-timeout-index-mdx": () => import("./../src/pages/blog/fetch-with-timeout/index.mdx" /* webpackChunkName: "component---src-pages-blog-fetch-with-timeout-index-mdx" */),
  "component---src-pages-blog-a-form-fitted-z-shell-index-mdx": () => import("./../src/pages/blog/a-form-fitted-z-shell/index.mdx" /* webpackChunkName: "component---src-pages-blog-a-form-fitted-z-shell-index-mdx" */),
  "component---src-pages-blog-customizing-tachyons-index-mdx": () => import("./../src/pages/blog/customizing-tachyons/index.mdx" /* webpackChunkName: "component---src-pages-blog-customizing-tachyons-index-mdx" */),
  "component---src-pages-blog-no-red-squigglies-for-semicolons-index-mdx": () => import("./../src/pages/blog/no-red-squigglies-for-semicolons/index.mdx" /* webpackChunkName: "component---src-pages-blog-no-red-squigglies-for-semicolons-index-mdx" */),
  "component---src-pages-blog-functional-first-accordions-index-mdx": () => import("./../src/pages/blog/functional-first-accordions/index.mdx" /* webpackChunkName: "component---src-pages-blog-functional-first-accordions-index-mdx" */),
  "component---src-pages-blog-on-declarative-styling-index-mdx": () => import("./../src/pages/blog/on-declarative-styling/index.mdx" /* webpackChunkName: "component---src-pages-blog-on-declarative-styling-index-mdx" */)
}

